<!--外委维保用户管理-->
<template>
  <page-container title="外委维保用户列表" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="账号" prop="useraccount" style="width: 25%">
              <a-input v-model.trim="queryParams.useraccount" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="姓名" prop="username" style="width: 25%">
              <a-input v-model="queryParams.username" placeholder="请输入"></a-input>
            </a-form-model-item>
            <a-form-model-item label="机构" prop="maintenancedepid" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype=='3'?deptOptions.filter(item => item.deptype=='3'):deptOptionsAll.filter(item => item.deptype=='3')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype=='3'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择使用单位" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 25%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
          </a-form-model>
        </div>
        <div v-show="showAdvanced" class="advanced-query">
          <a-form-model ref="advancedForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="角色" prop="rolename" style="width: 25%">
              <a-select v-model="queryParams.rolename">
                <a-select-option value="">全部</a-select-option>
                <a-select-option value="维保组长">维保组长</a-select-option>
                <a-select-option value="维保组员">维保组员</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="账号状态" prop="status" style="width: 25%">
              <a-select v-model="queryParams.status">
                <a-select-option :value="1">启用</a-select-option>
                <a-select-option :value="0">停用</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">用户列表</h3>
          <div class="table-btns">
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :columns="tableColumns" :data-source="tableData" row-key="userid" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="status" slot-scope="text" class="status-view">
            <span class="dot" :class="{'light': text=='1'}"></span>
            <span class="text">{{text=='1' ? '启用' : '未启用'}}</span>
          </span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'username-'+record.userid">姓名重置</a-menu-item>
                <a-menu-item :key="'password-'+record.userid">密码重置</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <a-modal v-model="passwordVisible" :title="type=='password'?'修改密码':'修改姓名'">
      <template slot="footer">
        <a-button @click="passwordVisible=false">取消</a-button>
        <a-button type="primary" @click="save">确定</a-button>
      </template>
      <a-form-model ref="userForm" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:14}">
        <a-form-model-item label="用户名称" prop="username">
          <a-input v-model.trim="formData.username" :disabled="type=='password'"/>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phonenum">
          <a-input v-model.trim="formData.phonenum" :disabled="type=='password'"/>
        </a-form-model-item>
        <a-form-model-item v-if="type=='password'" label="新密码" prop="password">
          <a-input type="password" v-model.trim="formData.password" />
        </a-form-model-item>
        <a-form-model-item v-if="type=='password'" label="确认密码" prop="passwordConfirm">
          <a-input type="password" v-model.trim="formData.passwordConfirm" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </page-container>
</template>
<script>
import {
  getDictByDicType,
  getMaintenanceUserListByCondition,
  modifyPersonInfo
} from 'A/xtpz.js'
import {
  getItemFromArrayByKey,
  getCache
} from 'U'
import store from '@/store'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import deptselect from '@/mixins/deptselect'
import {getUserByUserid} from "A/login";
export default {
  name: 'maintenancePeopleManagement',
  props: {
    showTitle: {
      default: true
    },
    userDepId: {
      default: null
    }
  },
  mixins: [deptselect],
  data() {
    return {
      moment,
      passwordVisible:false,
      showAdvanced: false,
      modalBodyStyle: {
        maxHeight: '560px',
        overflowY: 'auto',
      },
      userTypes: [],
      breadcrumb: [
        {
          name: '系统管理',
          path: ''
        },
        {
          name: '外委维保用户',
          path: ''
        },
      ],
      queryParams: {
        useraccount: '',
        username: '',
        usertype: '',
        status: '',
        userdepid: '',
      },
      tableColumns: [
        {
          title: '账号',
          dataIndex: 'useraccount',
          key: 'useraccount'
        },
        {
          title: '姓名',
          dataIndex: 'username',
          key: 'username'
        },
        {
          title: '角色',
          dataIndex: 'rolename',
          key: 'rolename'
        },
        {
          title: '手机',
          dataIndex: 'phonenum',
          key: 'phonenum'
        },
        {
          title: '所属机构',
          dataIndex: 'userdepname',
          key: 'userdepname'
        },
        {
          title: '用户状态',
          dataIndex: 'status',
          key: 'status',
          sorter: (a, b) => a.status - b.status,
          scopedSlots: { customRender: 'status' }
        },
        {
          title: '操作',
          key: 'operation',
          scopedSlots: { customRender: 'operation' }
        },
      ],
      tableData: [],
      tableLoading: false,
      currentPage: 1,
      pagination: {
        total: 0,
        pageSize: 10,
      },
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalType: '',
      usertype:null,
      formData: {
        admin:'true',
        userid: '',
        username: '',
        password: '',
        passwordConfirm: '',
        phonenum:'',
        usertype:null,
        userdepid:null,
        playtype:null,
      },
      formRules: {
        username: [{required: true, message: '请输入用户姓名'}],
        // phonenum: [{required: true, message: '请输入手机号'}],
        passwordConfirm: [
          {
            validator: (rule, value, callback) => {
              if(value == this.formData.password) {
                callback();
              }else {
                callback('两次输入的密码不一致')
              }
            }, trigger: 'blur'
          }
        ],
      },
      type:'',
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo,
      menuList: state => state.menuList
    }),
    ...mapGetters(['uploadHeaders', 'uploadData']),
    modalTitle() {
      let title = '';
      if(this.modalType == 'add') {
        title = '新增';
      }else if(this.modalType == 'detail') {
        title = '详情';
      }else if(this.modalType == 'edit') {
        title = '修改';
      }else {
        title = '';
      }
      return title;
    },
  },
  watch: {
    modalVisible(val) {
      if(!val) {
        this.resetModal();
      }
    },
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.maintenancedepid = val[val.length-1];
      }else {
        this.queryParams.maintenancedepid = '';
      }
    }
  },
  beforeRouteEnter(to, from, next) {
    let toTab = getItemFromArrayByKey(store.state.main.tabs, 'id', to.name);
    if(!toTab) {
      store.commit('delKeepAlive', to.name)
    }
    next()
  },
  created() {
    this.init();
    this.initDeptOptionsAll();
  },
  methods: {
    init() {
      let logininfo=getCache("logininfo",true)
      if(logininfo){
        this.usertype=logininfo.usertype
      }
      let dictionary = getCache('dictionary', true);
      if(dictionary) {
        this.positionTypes = dictionary.positionTypes;
      }
      this.getUserTypes();
      this.getTableData();
    },
    getUserTypes() {
      let params = {
        dictype: '11'
      };
      getDictByDicType(params).then(res => {
        if(res && res.returncode == '0') {
          if(this.userInfo.usertype == '0') {
            if(this.userInfo.useraccount == 'admin') {
              this.userTypes = res.item;
            }else {
              this.userTypes = res.item.filter(item => item.dickey != '0');
            }
          }else {
            this.userTypes = res.item.filter(item => item.dickey == this.userInfo.usertype);
          }
        }
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected = [];
      this.$refs.queryForm.resetFields();
      this.$refs.advancedForm.resetFields();
      this.getTableData()
    },
    getTableData(firstPage) {
      if (firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        usertypes: 3,
        userdepid: "0",
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize,
      };
      getMaintenanceUserListByCondition(params).then(res => {
        this.tableLoading = false;
        if (res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    pageChange(page, size) {
      this.getTableData();
    },
    onShowSizeChange(page, size) {
      this.pagination.pageSize = size;
      this.getTableData(true);
    },
    showModal(type, value, record) {
      this.modalType = type;
      if(type == 'add') {
        this.modalVisible = true;
        this.$nextTick(() => {
          this.resetModal();
        })
      }else {
        this.modalVisible = true;
        this.showLoading();
        this.$nextTick(() => {
          let params = {
            userid: record.userid
          }
          getUserByUserid(params).then(res => {
            this.hideLoading();
            if(res && res.returncode == '0') {
              record = res.item;
              if(record.areaid) {
                this.modalForm.areacode = record.areaid;
                this.initAreaTreeOptions();
              }
              this.modalForm.deviceparamable=record.deviceparamable
              this.modalForm.userdepinfoable=record.userdepinfoable
              this.modalForm.incidentvisible=record.incidentvisible
              this.modalForm.userinforesetable=record.userinforesetable
              this.modalForm.isranking=record.isranking
              this.modalForm.deleteenable = record.deleteenable;
              this.modalForm.verification = record.verification;
              this.modalForm.verificationenable= record.verificationenable;
              this.modalForm.userid = record.userid;
              this.modifyUserdeptid = record.userdepid;
              this.modalForm.usertype = record.usertype;
              this.modalForm.useraccount = record.useraccount;
              this.modalForm.status = record.status;
              this.modalForm.username = record.username;
              this.modalForm.sex = record.sex;
              this.modalForm.phonenum = record.phonenum;
              this.modalForm.idnum = record.idnum;
              this.modalForm.telnum = record.telnum;
              this.modalForm.email = record.email;
              this.modalForm.selectedRoleList = record.roleList ? record.roleList.map(item => item.roleid) : [];
              this.modalForm.address = record.address;
              this.modalForm.istest = record.istest;
              this.modalForm.expiretime = record.expiretime ? moment(record.expiretime, 'YYYYMMDD') : null;
              this.modalForm.maintenancegroupid = record.maintenancegroupid;
              this.modalForm.maintenancenum = record.maintenancenum;
              this.modalForm.certificate1num = record.certificate1num;
              this.modalForm.certificate1timerange = null;
              this.modalForm.phonextnum=record.phonextnum;
              this.modalForm.phonename=record.phonename;
              this.modalForm.phonepassword=record.phonepassword;
              if(record.certificate1starttime && record.certificate1endtime) {
                this.modalForm.certificate1timerange = [moment(record.certificate1starttime, 'YYYYMMDD'),moment(record.certificate1endtime, 'YYYYMMDD')]
              }
              this.modalForm.certificate1pic = record.certificate1pic;
              if(record.certificate1pic) {
                let nameIndex = record.certificate1pic.lastIndexOf('/');
                if(nameIndex < 0) {
                  nameIndex = record.certificate1pic.lastIndexOf('\\');
                }
                let fileName = record.certificate1pic.substr(nameIndex+1);
                this.certificate1picList = [{
                  uid: 'certificate1picuid',
                  name: fileName,
                  status: 'done',
                  url: record.certificate1pic,
                }];
              }
              this.modalForm.certificate2num = record.certificate2num;
              this.modalForm.certificate2timerange = null;
              if(record.certificate2starttime && record.certificate2endtime) {
                this.modalForm.certificate2timerange = [moment(record.certificate2starttime, 'YYYYMMDD'),moment(record.certificate2endtime, 'YYYYMMDD')]
              }
              this.modalForm.certificate2pic = record.certificate2pic;
              if(record.certificate2pic) {
                let nameIndex = record.certificate2pic.lastIndexOf('/');
                if(nameIndex < 0) {
                  nameIndex = record.certificate2pic.lastIndexOf('\\');
                }
                let fileName = record.certificate2pic.substr(nameIndex+1);
                this.certificate2picList = [{
                  uid: 'certificate2picuid',
                  name: fileName,
                  status: 'done',
                  url: record.certificate2pic,
                }];
              }
              this.modalForm.coordinatetime = record.coordinatetime;
              this.modalForm.qualificationdesc = record.qualificationdesc;
              this.modalForm.userdesc = record.userdesc;
              this.modalForm.bigtitle = record.bigtitle;
              this.modalForm.remotenable = record.remotenable;
            }else {
              this.$message.error(res.errormsg||'查询失败');
            }
          })
        })
      }
    },
    operationClick({key}) {
      let arr = key.split('-');
      let type = arr[0];
      let userid = arr[1];
      this.selectedUser = getItemFromArrayByKey(this.tableData, 'userid', userid);
      if(type == 'password') {
        this.formData.userid=this.selectedUser.userid
        this.formData.playtype=this.selectedUser.playtype
        this.formData.userdepid=this.selectedUser.userdepid
        this.formData.username=this.selectedUser.username
        this.formData.usertype=this.selectedUser.usertype
        this.formData.phonenum=this.selectedUser.phonenum
        this.formData.password=""
        this.formData.passwordConfirm=""
        this.type='password'
        this.passwordVisible=true
      }else if(type == 'username') {
        this.formData.userid=this.selectedUser.userid
        this.formData.playtype=this.selectedUser.playtype
        this.formData.userdepid=this.selectedUser.userdepid
        this.formData.username=this.selectedUser.username
        this.formData.usertype=this.selectedUser.usertype
        this.formData.phonenum=this.selectedUser.phonenum
        this.formData.password=""
        this.formData.passwordConfirm=""
        this.type='username'
        this.passwordVisible=true
      }
    },
    save() {
      this.$refs.userForm.validate(valid => {
        if(valid) {
          this.resetPassword();
        }else {
          return false;
        }
      })
    },
    resetPassword() {
      this.showLoading();
      let params = {
        ...this.formData,
      };
      modifyPersonInfo(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.$message.success('操作成功');
          this.passwordVisible=false;
          this.getTableData();
        }else {
          this.$message.error(res.errormsg||'操作失败')
        }
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.menu-modal-container {
  // display: flex;
  // position: relative;
  height: 100%;
  overflow-y: auto;
  margin-right: -24px;
}
</style>